<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-select
          :value="campoParaClassificacao"
          :items="camposParaClassificacao"
          name="campoParaClassificacao"
          label="Campo para classificação dos dados"
          @change="setCampoParaClassificacao"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-subheader class="pl-0"> Tamanho do raio de cada ponto </v-subheader>
        <v-slider
          min="10"
          max="30"
          thumb-label
          :value="raioPontos"
          @change="setCampoRaioPontos"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-subheader class="pl-0"> Desfoque dos pontos </v-subheader>
        <v-slider
          min="10"
          max="30"
          thumb-label
          :value="desfoquePontos"
          @change="setCampoDesfoquePontos"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const camposIgnorados = ['dist', 'bdgd_versao'];

const tiposPermitidos = ['decimal', 'inteiro'];

export default {
  props: {
    campos: {
      type: Array,
      default: () => []
    },
    tiposDadosCampos: {
      type: Object,
      default: () => {}
    },
    relatorio: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    campoParaClassificacao() {
      return this.$store.getters.getSimbologiaConfigMapaCalor
        .campoParaClassificacao;
    },

    raioPontos() {
      return this.$store.getters.getSimbologiaConfigMapaCalor.raioPontos;
    },

    desfoquePontos() {
      return this.$store.getters.getSimbologiaConfigMapaCalor.desfoquePontos;
    },

    camposParaClassificacao() {
      return this.campos
        .filter((campo) => {
          return (
            !camposIgnorados.includes(campo) &&
            tiposPermitidos.includes(this.tiposDadosCampos[campo])
          );
        })
        .sort();
    }
  },
  methods: {
    setCampoParaClassificacao(campo) {
      this.$store.dispatch(
        'setSimbologiaConfigMapaCalorCampoParaClassificacao',
        campo
      );
    },

    setCampoRaioPontos(raio) {
      this.$store.dispatch('setSimbologiaConfigMapaCalorRaioPontos', raio);
    },

    setCampoDesfoquePontos(raio) {
      this.$store.dispatch('setSimbologiaConfigMapaCalorDesfoquePontos', raio);
    }
  }
};
</script>
